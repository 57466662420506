import { AuthService } from './../../seguranca/auth.service';
import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

// Variable in assets/js/scripts.js file
declare var AdminLTE: any;

@Component({
  selector: "app-usuario-gerenciar",
  templateUrl: "./usuario-gerenciar.component.html"
})
export class UsuarioGerenciarComponent implements OnInit {

  parlamentar: boolean;

  constructor(
    public auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    if (this.router.url === '/usuarios') {
      this.router.navigate(["/usuarios/listar"]);
    }
  }


}
