import { Component, OnInit } from "@angular/core";

// Variable in assets/js/scripts.js file
declare var AdminLTE: any;

@Component({
  selector: "modulo-nao-disponivel",
  template: `

    <style>
    .shadow {
        -webkit-box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 1);
      }
      
      .hide-div {
        display: none !important;
      }
      
      .limiter {
        width: 100%;
        margin: 0 auto;
      }
      
      .container-login100 {
        width: 100%;
        min-height: 100vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 30px;
        /* background: #ebeeef; */
      }
      
      .wrap-login100 {
        width: 800px;
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
      }
      
      .txt1 {
        font-family: Arial;
        font-size: 13px;
        line-height: 1.4;
        color: #999999;
      }
      
      .txt2 {
        font-family: Arial;
        font-size: 15px;
        line-height: 1.4;
        color: #999999;
        margin-top: 15px;
        font-weight: bold;
      }
      
      /*==================================================================
        [ Title form ]*/
      .login100-form-title {
        width: 100%;
        position: relative;
        z-index: 1;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
      
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      
        padding: 20px 0px;
      }
      
      .login100-form-title-1 {
        font-family: Arial;
        font-size: 30px;
        color: #fff;
        text-transform: uppercase;
        line-height: 1.2;
        text-align: center;
      }
      .login100-form-title-2 {
        font-family: Arial;
        font-size: 20px;
        color: #fff;
        text-transform: uppercase;
        line-height: 1.2;
        text-align: center;
      }
      
      .login100-form-title::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(54, 84, 99, 0.7);
      }
      
      /*==================================================================
        [ Form ]*/
      
      .login100-form {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 40px 100px 20px 170px;
      }
      
      /*------------------------------------------------------------------
        [ Input ]*/
      
      .wrap-input100 {
        width: 100%;
        position: relative;
        border-bottom: 1px solid #b2b2b2;
      }
      
      .label-input100 {
        font-family: Arial;
        font-size: 15px;
        color: #808080;
        line-height: 1.2;
        text-align: right;
      
        position: absolute;
        top: 14px;
        left: -105px;
        width: 80px;
      }
      
    </style>

    <div class="container-login100 animated fadeIn">
        <div class="wrap-login100 shadow">
            <div class="login100-form-title">
                <img src="../../../assets/imgs/logo-vertical.png" height="100" style="margin: 10px;">
            </div>
        
            <div class="col-sm-12">
                <br>
                <h3>Info

                    <a href="javascript: history.go(-1)" class="btn btn-default btn-sm pull-right">Voltar</a>
                </h3>
                <hr>
            </div>
        
            <div class="col-sm-12 text-center">
                <h3>Não foi possível encontrar a página solicitada.</h3>
                <p>
                    Enquanto isso, você pode
                    <a href="javascript: history.go(-1)">voltar</a> e tentar outra solicitação.
                </p>
                <br>
                <br>
            </div>
        
            <div class="col-sm-12">
                <hr>
                <p class="text-center" style="margin-bottom: 15px; color: #ccc;">by nflsoftware.com</p>
            </div>
        
        </div>

    </div>

  `,
  styles: []
})
export class ModuloNaoDisponivelComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();
  }
}
