import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-wrapper",
  templateUrl: "./wrapper.component.html"
})
export class WrapperComponent {

  constructor(private router: Router) {
    if(this.router.url == "/"){
      this.router.navigate(["home"]);
    }else{
      this.router.navigate([this.router.url]);
    }
  }

}
