import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { ConfirmationService } from 'primeng/components/common/api';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { Page } from '../../core/pagination/pagination.model';
import { UsuarioFiltro, UsuarioService } from '../usuario.service';
import { AuthService } from './../../seguranca/auth.service';
import { PerfilService } from '../../core/services/perfil-permissao/perfil.service';

declare var AdminLTE: any;

@Component({
  selector: 'app-usuario-listar',
  templateUrl: './usuario-listar.component.html',
})
export class UsuarioListarComponent implements OnInit {
  totalRegistros = 0;

  filtroUsuario = new UsuarioFiltro();

  usuarios = [];
  loading;
  parlamentar: boolean;
  perfis = [];

  constructor(
    private usuarioService: UsuarioService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    private title: Title,
    public auth: AuthService,
    private router: Router,
    private perfilService: PerfilService
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.smooth("headerPrincipal")

    this.title.setTitle('Listagem dos usuários');
    this.pesquisar();
    this.carregaPerfis()
    this.filtroUsuario.perfil = ''

  }

  carregaPerfis() {
    this.perfilService.listarTodos()
      .then(retorno => {
        this.perfis = retorno
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //LISTAR
  page: Page;
  pesquisar(pagina = 0) {
    this.filtroUsuario.pagina = pagina;
    this.filtroUsuario.itensPorPagina = 20

    this.usuarioService.pesquisar(this.filtroUsuario).then(resultado => {
      this.loading = true
      this.usuarios = resultado.content;
      this.page = resultado;

    });
  }

  // === CONFIGURACOES DE PAGINACAO
  changePage(event) {
    this.filtroUsuario.itensPorPagina = event.size
    this.filtroUsuario.pagina = event.page
    this.usuarioService.pesquisar(this.filtroUsuario).then(res => {
      this.page = res
      this.usuarios = res.content;
    });
  }

  //EXCLUIR
  confirmarExclusao(pessoa: any) {
    this.confirmation.confirm({
      message: 'Tem certeza que deseja excluir?',
      accept: () => {
        this.excluir(pessoa);
      },
    });
  }
  excluir(pessoa: any) {
    this.usuarioService
      .excluir(pessoa.id)
      .then(() => {
        this.pesquisar();
        this.toasty.success('Pesssoa excluída com sucesso!');
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  gotoEditar(param) {
    this.router.navigate(["/usuarios/editar"]);
    sessionStorage.setItem('usuario', param)
  }

  smooth(elementId: string) {
    if (elementId != null) {
      setTimeout(
        function () {
          document.getElementById(elementId).scrollIntoView({
            behavior: "smooth"
          });
        }.bind(this),
        1
      );
    }
  }

}
