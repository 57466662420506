import { Component, OnInit } from '@angular/core';
import { ToastyConfig } from "ng2-toasty";

declare var AdminLTE: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  body: HTMLBodyElement = document.getElementsByTagName('body')[0];

  constructor(
    private toastyConfig: ToastyConfig,
  ) {
    this.toastyConfig.theme = 'material';
    this.body.classList.add('skin');
    this.body.classList.add('sidebar-mini');
  }

  ngOnInit() {
    AdminLTE.init();
  }

}
