import { Component, OnInit } from "@angular/core";

import { ChangeDetectionStrategy } from "@angular/core";
import { Subject } from "rxjs";

// Variable in assets/js/scripts.js file
declare var AdminLTE: any;

@Component({
  selector: "app-convite",
  templateUrl: "./convite.component.html"
})
export class ConviteComponent implements OnInit {
  public showPreview: boolean = false;
  public initialContentOne: string = "Carregando conteúdo inicial...";
  public contentOne: string;
  public update$: Subject<any> = new Subject();
  public options1: any = {
    autogrow: true,
    removeformatPasted: true,
    semantic: false,
    btns: [
      ["bold", "italic"],
      ["link"],
      ["foreColor", "backColor"],
      ["preformatted"],
      ["noembed"]
    ]
  };

  togglePreview() {
    this.showPreview = !this.showPreview;
    if (this.showPreview) this.update$.next();
  }

  constructor() {
    //Initial content update.
    setTimeout(() => {
      this.initialContentOne = "<p>Digite aqui o conteúdo do e-mail</p>";
      this.update$.next(); // this is needed only when you use ChangeDetectionStrategy.OnPush strategy
    }, 2000);
  }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

  }
}
