import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModuloNaoDisponivelComponent } from './core/not-found/modulo-nao-disponivel.component';
import { HomePageComponent } from './home/home/home-page.component';
import { UsuarioViewComponent } from './home/info/usuario-view/usuario-view.component';
import { AuthGuard } from './seguranca/auth.guard';
import { LoginFormComponent } from './seguranca/login-form/login-form.component';
import { WrapperComponent } from './wrapper/wrapper.component';

const routes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginFormComponent },
  {
    path: '', component: WrapperComponent, canActivate: [AuthGuard], children: [

      { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
      { path: 'usuario-view', component: UsuarioViewComponent, canActivate: [AuthGuard] },

      // ===== LAZY-LOADINGS ==== //
      { path: 'usuarios', loadChildren: 'app/usuario/usuario.module#UsuarioModule' },
      { path: 'unidades', loadChildren: 'app/unidade-saude/unidade.module#UnidadeModule' },
      { path: 'atendimentos', loadChildren: 'app/atendimentos/atendimento.module#AtendimentoModule' },
      { path: 'fornecedores', loadChildren: 'app/fornecedores/fornecedor.module#FornecedorModule' },
      { path: 'estoque', loadChildren: 'app/estoque/estoque.module#EstoqueModule' },
      { path: 'conversas', loadChildren: 'app/conversas/conversa.module#ConversaModule' },
      { path: 'grupos', loadChildren: 'app/grupos/grupo.module#GrupoModule' },
      { path: 'exames', loadChildren: 'app/exames/exame.module#ExameModule' },
      { path: 'dicom', loadChildren: 'app/dicom/dicom.module#DicomModule' },
      
      { path: 'cadastros', loadChildren: 'app/cadastros/cadastros.module#CadastrosModule' },
      { path: 'perfil-permissao', loadChildren: 'app/perfil-permissao/perfil-permissao.module#PerfilPermissaoModule' },

      { path: 'notificacao', loadChildren: 'app/notificacao/notificacao.module#NotificacaoModule' },

    ]
  },

  { path: 'modulo-nao-disponivel', component: ModuloNaoDisponivelComponent },
  { path: '**', redirectTo: 'modulo-nao-disponivel' }
];

@NgModule({
  // = debugging purposes only
  // imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
