import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxMaskModule } from 'ngx-mask';
import { AtendimentoService } from "../core/services/hospitalar/atendimento.service";
import { NotificacaoService } from "../core/services/notificacao/notificacao.service";
import { NotificacaoHasUsuarioService } from "../core/services/notificacao/notificacaoHasUsuario.service";
import { UsuarioModule } from './../usuario/usuario.module';
import { UsuarioService } from './../usuario/usuario.service';
import { HomePageComponent } from "./home/home-page.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxMaskModule,

    UsuarioModule
  ],
  declarations: [
    HomePageComponent,
  ],
  providers:[
    UsuarioService,
    NotificacaoService,
    NotificacaoHasUsuarioService,
    AtendimentoService
  ]
})
export class HomeModule {}
