import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AuthService } from './../../seguranca/auth.service';
import { environment } from "../../../environments/environment";
import { NotificacaoHasUsuarioFiltro, NotificacaoHasUsuarioService } from "../../core/services/notificacao/notificacaoHasUsuario.service";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { AtendimentoService } from "../../core/services/hospitalar/atendimento.service";
import { AtendimentoIndicadorHeaderDTO } from "../../core/models/hospitalar";

declare var AdminLTE: any;

@Component({
  selector: "app-home",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"]
})
export class HomePageComponent implements OnInit {

  listaNotificacao = [];
  caminhoVideo: string;

  filtroNotificacaoHasUsuario = new NotificacaoHasUsuarioFiltro();

  atendimentoIndicadorHeaderDTO = new AtendimentoIndicadorHeaderDTO

  constructor(
    public auth: AuthService,
    private title: Title,
    private notificacaoHasUsuarioService: NotificacaoHasUsuarioService,
    private router: Router,
    private toasty: ToastyService,
    private errorHandler: ErrorHandlerService,
    private atendimentoService: AtendimentoService,
  ) { }

  ngOnInit() {
    AdminLTE.init();
    this.title.setTitle("Home");

    this.smooth("headerPrincipal")

    this.quantidadeAlunos();
    this.quantidadeProfessor()
    this.quantidadeEscolas();
    this.quantidadeTurmas();

    this.listarNotificacoesHasUser();
    this.caminhoVideo = environment.downloadFileUrl + "/video3.mp4"

    this.buscaIndicadoresHeader()
  }

  buscaIndicadoresHeader() {
    this.atendimentoService.buscarIndicadorHeader()
      .then(res => {
      
        this.atendimentoIndicadorHeaderDTO = res

      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  //==================Listagem dos Cards
  countAlunos
  quantidadeAlunos() {
    this.countAlunos = 2576
  }
  countProfessor
  quantidadeProfessor() {
    this.countProfessor = 1650
  }
  countEscolas
  quantidadeEscolas() {
    this.countEscolas = 87
  }
  countTurmas
  quantidadeTurmas() {
    this.countTurmas = 128

  }
  //===========.Fim listagem 

  listarNotificacoesHasUser() {
    this.filtroNotificacaoHasUsuario.itensPorPagina = 5;
    this.filtroNotificacaoHasUsuario.usuario = this.auth.jwtPayload.id
    this.notificacaoHasUsuarioService.pesquisar(this.filtroNotificacaoHasUsuario).then(lista => {
      this.listaNotificacao = lista.content
    })
  }

  setaNotificacaoSessao(notificacao) {
    if (notificacao.statusNotificacao === 'Lida') {
      sessionStorage.setItem('verNotificacao', JSON.stringify(notificacao));
      this.router.navigate(['/notificacao/visualizar']);
    } else {
      notificacao.statusNotificacao = 'Lida'
      this.notificacaoHasUsuarioService.atualizar(notificacao)
        .then(resultado => {
          this.toasty.info('Aviso visualizado.');
          sessionStorage.setItem('verNotificacao', JSON.stringify(resultado));
          this.router.navigate(['/notificacao/visualizar']);
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

  smooth(elementId: string) {
    if (elementId != null) {
      setTimeout(
        function () {
          document.getElementById(elementId).scrollIntoView({
            behavior: "smooth"
          });
        }.bind(this),
        1
      );
    }
  }

}