
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { JwtHelper } from 'angular2-jwt';
import { ConfirmationService } from 'primeng/primeng';
import { AuthService } from '../seguranca/auth.service';
import { ErrorHandlerService } from './error-handler.service';
import { ModuloNaoDisponivelComponent } from './not-found/modulo-nao-disponivel.component';

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    RouterModule,
  ],
  declarations: [
    ModuloNaoDisponivelComponent,
  ],
  exports: [
    ModuloNaoDisponivelComponent,
  ],
  providers: [
    Title,
    AuthService,
    JwtHelper,
    { provide: LOCALE_ID, useValue: 'pt-BR' },

    ConfirmationService,
    ErrorHandlerService,

  ]
})
export class CoreModule {}
