import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class UtilService {

    addUmDia(data: any): Promise<any> {
        data = new Date(data)
        data.setDate(data.getDate() + 2)
        data = moment(data).format("YYYY-MM-DD");
        return data;
    }

}