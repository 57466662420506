export const environment = {

  production: false,

  // API SPRING
  apiUrl:           "https://api-spring.medsafebrasil.com.br",
  // apiUrl:           "http://app.nflsoftware.com:8089",
  // apiUrl:           "http://localhost:8080",
  // apiUrl:           "http://164.92.124.195:8088",

  // API NODE
  downloadFileUrl:  "https://api-node.medsafebrasil.com.br/download",
  uploadFileUrl:    "https://api-node.medsafebrasil.com.br/upload",
  dicom:            "https://app.nflsoftware.com:8001/dicom",
  // dicom:            "https://api-node.medsafebrasil.com.br/dicom",
  urlBlockChain:    "https://api-node.medsafebrasil.com.br/blockchain",
  // downloadFileUrl:  "http://164.92.124.195:4001/download",
  // uploadFileUrl:    "http://164.92.124.195:4001/upload",
  // dicom:            "http://164.92.124.195:4001/dicom",
  // urlBlockChain:    "http://164.92.124.195:4001/blockchain",

  // API PARTNER
  urlChatWeb:       "https://chating-nfl.vercel.app/?userId=",
  urlMeetJitsi:     "https://jitsimeet.vercel.app?meetRoom=",
  urlAssinatura:    "https://signer.nscode.solutions/#/pades-signature?local=helpi"
};