export class Usuario {
  id;
  nome: string;
  sus: string;
  sexo;
  dataNascimento;
  email: string;
  telefone;
  senha;
  senha_antiga;
  cpf: string;
  tipoDocumento;
  rg: string;
  funcao;
  perfil = new Perfil();
  matricula;
  efetivo;

  qualificacao;
  token_email;
  excluido;
  
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: any;
  cidade: string;
  estado: string;
  user;
}

export class EsqueciSenha {
  email: String;
  token: String;
  senha: String;
}

export class Cidadao {
  id: number;
  nome: string;
  email: string;
  cpf: string;
  rg: String;
  senha: String;

  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: any;
  cidade: string;
  estado: string;

  excluido;
}



export class Perfil {
  id: number;
  nome: string;
  permissao = new Permissao();
  excluido;
}
export class Permissao {
  id: number;
  descricao: string;
  atribuicoes: string;
  modulo: string;
  excluido;
}
export class PerfilPermissao {
  id: number;
  status: boolean;
  perfil = new Perfil();
  permissao = new Permissao();
  excluido;
}
export class PerfilHasPerfil {
  id: number;
  status: boolean;
  perfilPrincipal = new Perfil();
  perfilPermitido = new Perfil();
  excluido;
}

export class EsqueciEmail {
  email: String;

  excluido;
}
export class DataUpload {
  destination;
  encoding;
  fieldname;
  filename;
  mimetype;
  originalname;
  path;
  size;
}



