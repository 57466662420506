import { environment } from "../../../../environments/environment";
import { URLSearchParams } from "@angular/http";
import { Injectable } from "@angular/core";
import { AuthHttp } from "angular2-jwt";

import * as moment from 'moment';

import "rxjs/add/operator/toPromise";

import { AuthService } from "../../../seguranca/auth.service";
import { Atendimento, AtendimentoDTO } from "../../models/hospitalar";


export class AtendimentoFiltro {
    nomePaciente;
    nomeUnidade;
    situacao;
    usuarioCadastro;
    paciente;
    unidadeSaude;
    tipoAtendimento;
    medico;
    perfil;
    de;
    ate;
    pagina = 0;
    itensPorPagina = 20;
}

@Injectable()
export class AtendimentoService {
    url: string;

    constructor(
        private http: AuthHttp,
        public auth: AuthService
    ) {
        this.url = `${environment.apiUrl}/atendimento`;
    }

    pesquisar(filtro: AtendimentoFiltro): Promise<any> {
        const params = new URLSearchParams();

        params.set("page", filtro.pagina.toString());
        params.set("size", filtro.itensPorPagina.toString());

        if (filtro.situacao) {
            params.set("situacao", filtro.situacao);
        }
        if (filtro.usuarioCadastro) {
            params.set("usuarioCadastro", filtro.usuarioCadastro);
        }
        if (filtro.paciente) {
            params.set("paciente", filtro.paciente);
        }
        if (filtro.unidadeSaude) {
            params.set("unidadeSaude", filtro.unidadeSaude);
        }
        if (filtro.tipoAtendimento) {
            params.set("tipoAtendimento", filtro.tipoAtendimento);
        }
        if (filtro.medico) {
            params.set("medico", filtro.medico);
        }
        if (filtro.nomePaciente) {
            params.set("nomePaciente", filtro.nomePaciente);
        }
        if (filtro.nomeUnidade) {
            params.set("nomeUnidade", filtro.nomeUnidade);
        }

        if (filtro.perfil) {
            params.set("perfil", filtro.perfil);
        }





        if (filtro.de) {
            params.set("de", filtro.de);
        }
        if (filtro.ate) {
            params.set("ate", filtro.ate);
        }






        return this.http
            .get(`${this.url}/por-unidade`, { search: params })
            .toPromise()
            .then(response => {
                const responseJson = response.json();
                return responseJson;
            });
    }

    listarTodos(): Promise<any> {
        const params = new URLSearchParams();
        return this.http
            .get(`${this.url}`, { search: params })
            .toPromise()
            .then(response => response.json().content);
    }

    excluir(codigo: number): Promise<void> {
        return this.http
            .delete(`${this.url}/${codigo}`)
            .toPromise()
            .then(() => null);
    }

    adicionar(entidade: Atendimento): Promise<Atendimento> {
        return this.http
            .post(this.url, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json());
    }

    adicionarComPaciente(entidade: AtendimentoDTO): Promise<Atendimento> {
        return this.http
            .post(`${this.url}/com-paciente`, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json());
    }

    atualizar(entidade: Atendimento): Promise<Atendimento> {
        return this.http
            .put(`${this.url}/${entidade.id}`, JSON.stringify(entidade))
            .toPromise()
            .then(response => response.json() as Atendimento);
    }

    buscarPorCodigo(codigo: number): Promise<Atendimento> {
        return this.http
            .get(`${this.url}/${codigo}`)
            .toPromise()
            .then(response => {
                const retorno = response.json() as Atendimento;
                return retorno;
            });
    }

    buscarIndicadorHeader(): Promise<any> {
        return this.http
            .get(`${this.url}/indicador-header`)
            .toPromise()
            .then(response => {
                const retorno = response.json() as any;
                return retorno;
            });
    }
}
