import { Location } from '@angular/common';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Usuario } from "../../core/models/model";
import { AuthService } from "../../seguranca/auth.service";
import { UsuarioFiltro, UsuarioService } from "../usuario.service";
import { PessaoUtil } from "./../../core/pessoa.util";
import { PerfilHasPerfilFiltro, PerfilHasPerfilService } from './../../core/services/perfil-permissao/perfil-has-perfil.service';
import { GetAdress } from './../../core/services/utils/get-address.service';
import { PerfilService } from '../../core/services/perfil-permissao/perfil.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UtilService } from '../../core/services/utils/util.service';
import * as moment from 'moment';


// Variable in assets/js/scripts.js file
declare var AdminLTE: any;

@Component({
  selector: "app-usuario-cadastro",
  templateUrl: "./usuario-cadastro.component.html"
})
export class UsuarioCadastroComponent implements OnInit {
  usuario = new Usuario();

  filtroUsuario = new UsuarioFiltro();
  pessoaCpf = new PessaoUtil();
  cpfTemp: any
  emailTemp: any


  entidade;
  tipoEntidade;

  entidadesDoConcedente: any;
  perfis = [];


  passwordAlso;
  password;
  confirmPassword;
  parlamentar: boolean;
  areaMoradia: string;

  sexo = [
    { label: 'Masculino', value: 'Masculino' },
    { label: 'Feminino', value: 'Feminino' },
  ];

  constructor(
    private perfilService: PerfilService,
    public auth: AuthService,
    private perfilHasPerfilService: PerfilHasPerfilService,
    private getAdress: GetAdress,
    private usuarioService: UsuarioService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private location: Location,
    private util: UtilService,
  ) { }

  ngOnInit() {
    // Update the AdminLTE layouts
    AdminLTE.init();

    this.verificaRota()
    this.smooth("headerPrincipal")


  }

  verificaRota() {
    if (this.router.url === '/usuarios/editar') {
      const codigoPessoa = sessionStorage.getItem("usuario");
      this.carregarPessoa(codigoPessoa);
      this.title.setTitle("Editar usuário");
      this.carregarPerfis(this.auth.jwtPayload.perfil_id);
    } else if (this.router.url === '/usuarios/novo') {
      this.usuario.perfil.id = null
      this.usuario.sexo = ""
      sessionStorage.removeItem("usuario")
      this.title.setTitle("Novo usuário");
      this.carregarPerfis(this.auth.jwtPayload.perfil_id);
    }
  }

  goBack() {
    this.location.back();
  }

  carregarPessoa(codigo) {
    this.usuarioService.buscarPorCodigo(codigo)
      .then(pessoa => {
        this.usuario = pessoa;
        //Inconsistência de fuso horário dos servidores
        //this.usuario.dataNascimento = this.util.addUmDia(this.usuario.dataNascimento)
        this.cpfTemp = pessoa.cpf
        this.emailTemp = pessoa.email
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  get editando() {
    return Boolean(this.usuario.id);
  }

  salvar() {
    if (this.editando) {
      this.atualizarUsuario();
    } else {
      this.adicionarPessoa();
    }
  }

  adicionarPessoa() {
    this.usuarioService.adicionar(this.usuario)
      .then(() => {
        if (this.tipoEntidade != 'Concedente' && this.tipoEntidade != 'Proponente') {
          this.toasty.success("Usuário cadastrado com sucesso!");
          this.router.navigate(["/usuarios/listar"]);
        }
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  atualizarUsuario() {
    if (this.password && this.confirmPassword) {
      if (this.password === this.confirmPassword) {
        this.usuario.senha = this.password;
        this.usuario.senha_antiga = this.passwordAlso;
        this.usuarioService
          .atualizar(this.usuario)
          .then(retorno => {
            this.usuario = retorno;
            this.toasty.success("Usuário alterado com sucesso!");

            this.router.navigate(["/usuarios/listar"]);
          })
          .catch(erro => this.errorHandler.handle(erro));
      } else {
        this.errorHandler.handle("Senhas não conferem.");
      }
    } else {
      this.usuarioService.atualizar(this.usuario)
        .then(pessoa => {
          this.toasty.success("Usuário alterado com sucesso!");
          this.router.navigate(["/usuarios/listar"]);
        })
        .catch(erro => this.errorHandler.handle(erro));
    }
  }

  resetarSenha() {
    this.usuarioService
      .resetarSenha(this.usuario)
      .then(pessoa => {
        this.usuario = pessoa;
        this.toasty.success("Senha resetada com sucesso!");
        this.router.navigate(["/usuarios/listar"]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  async carregarPerfis(perfilPrincipal) {
    let phpf = new PerfilHasPerfilFiltro()
    phpf.perfilPrincipal = perfilPrincipal;
    await this.perfilHasPerfilService
      .pesquisar(phpf)
      .then(async resultado => {
        let perfisArray = [];
        await resultado.dados.forEach(vinculado => {
          perfisArray.push(vinculado.perfilPermitido);
        });

        this.perfis = perfisArray.map(p => ({ label: p.nome, value: p.id }));
      });
  }

  getEnderecoCep(event) {
    let value = event.target.value;
    if (value) {
      this.getAdress
        .getEnderecoCep(value)
        .then(response => {
          let data = response
          if (data.erro) {
            this.toasty.error("CEP inválido.");
            return;
          }
          this.usuario.cep = data.cep;
          this.usuario.endereco = data.logradouro;
          this.usuario.complemento = data.complemento;
          this.usuario.bairro = data.bairro;
          this.usuario.cidade = data.localidade;
          this.usuario.estado = data.uf;
        })
        .catch(erro => this.errorHandler.handle(erro));

    }
  }

  // ========== VALIDACAO CPF E EMAIL
  verificarCpf() {
    if (this.usuario.cpf == null || this.usuario.cpf == '')
      return this.toasty.warning('CPF não informado!');

    if (this.pessoaCpf.vercpf(this.usuario.cpf) != false) {

      if (this.usuario.cpf != null && this.usuario.cpf.length > 10) {

        if (this.cpfTemp != this.usuario.cpf) {

          this.filtroUsuario.cpf = this.usuario.cpf

          this.usuarioService.pesquisar(this.filtroUsuario).then(resultado => {

            if (resultado.totalElements == 1) {

              this.toasty.error("CPF já cadastrado!")

              this.usuario.cpf = null
            }

          });
        }

      } else {
        this.toasty.warning("Por Favor preencha o CPF !")
      }

    } else {
      this.toasty.info("CPF INVÁLIDO")
      this.usuario.cpf = null
    }

  }
  verificarEmail() {


    if (this.usuario.email != null) {

      if (this.emailTemp != this.usuario.email) {

        this.usuario.email = this.usuario.email.toLowerCase();

        let filtro = new UsuarioFiltro();
        filtro.entidadeConcedente = this.auth.jwtPayload.concedente
        filtro.email = this.usuario.email

        this.usuarioService.pesquisar(filtro).then(resultado => {

          if (resultado.totalElements != 0) {

            this.toasty.error("E-mail já cadastrado!")

            this.usuario.email = null
          }

        });

      }
    }

  }

  // =====  VIEWS STEPS
  etapa1;
  etapa2;
  etapa3;
  etapa4;
  etapa5;
  etapa6;
  etapa7;
  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
  }
  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
  }
  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
  }
  openEtapa4() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = true;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = false;
  }
  openEtapa5() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = true;
    this.etapa6 = false;
    this.etapa7 = false;
  }
  openEtapa6() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = true;
    this.etapa7 = false;
  }
  openEtapa7() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
    this.etapa5 = false;
    this.etapa6 = false;
    this.etapa7 = true;
  }

  //UPLOAD IMAGEM
  imageChangedEvent: any = '';
  croppedImage: any = '';
  choosedImg
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.choosedImg = true;
    // this.entidade.logotipo = event.base64 //change for user
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  checkEfetividade(event) {
    if (event.target.checked) {
      this.usuario.efetivo = 1
    } else {
      this.usuario.efetivo = 0
    }
  }

  smooth(elementId: string) {
    if (elementId != null) {
      setTimeout(
        function () {
          document.getElementById(elementId).scrollIntoView({
            behavior: "smooth"
          });
        }.bind(this),
        1
      );
    }
  }

}
