import { Perfil, Usuario } from "./model";

export class AtendimentoDTO {
	atendimento = new Atendimento()
	paciente = new Usuario()
	nota = new Nota()
}

export class AtenRegulacao {
	id;
	dataFato;
	justificativa;
	atendimento = new Atendimento;
	unidadeOrigem = new UnidadeSaude;
	unidadeDestino = new UnidadeSaude;
	medicoOrigem = new Usuario;
	medicoDestino = new Usuario;
	dataLog;
	usuario = new Usuario;
}

export class Atendimento {
	id;
	peso;
	idade;
	altura;
	situacao;
	dataCadastro;
	usuarioCadastro = new Usuario();
	paciente = new Usuario();
	unidadeSaude = new UnidadeSaude();
	tipoAtendimento = new TipoAtendimento();
	medico = new Usuario();
}

export class AtendimentoIndicadorHeaderDTO {
	atendimento;
	laudo;
	medico;
	unidadeSaude;
}

export class AtendimentoHasComorbidade {
	id;
	comorbidade = new Comorbidade();
	atendimento = new Atendimento();
}

export class AtendimentoHasExame {
	id;
	idDicom;
	atendimento = new Atendimento();
	exame = new Exame();
	usuario = new Usuario();
	data;
}

export class Comorbidade {
	id;
	comorbidade;
	descricao;
}

export class Exame {
	id;
	idDicom;
	descricao;
	usuario = new Usuario();
	data;
	paciente = new Usuario();
	laudo;
	laudoMedico = new Usuario();
	laudoStatus;
	laudoData;
	anexo;
	hashAnexo;
	tamanhoAnexo;
	tipo;
}

export class ExameDTO {
	exame = new Exame()
	atendimento = new Atendimento();
}

export class Nota {
	id;
	nota;
	data;
	tipo;
	atendimento = new Atendimento();
	usuario = new Usuario();
}

export class ProtocoloAtendimento {
	id;
	descricao;
	tipo;
	tipoAtendimento = new TipoAtendimento();
}

export class RespostaProtocolo {
	id;
	respostaText;
	respostaDatetime;
	atendimento = new Atendimento();
	protocoloAtendimento = new ProtocoloAtendimento();
}

export class TipoAtendimento {
	id;
	tipo;
	descricao;
	parecer;
}

export class UnidadeSaude {
	id
	nome
	cnes
	cnpj
	email
	telefone
	latitude
	longitude
	endereco
	numero
	bairro
	complemento
	cep
	cidade
	estado
	dataCadastro
	usuarioCadastro = new Usuario()
}

export class UsuarioHasUnidadeSaude {
	id;
	dataCadastro;
	usuarioCadastro = new Usuario();
	usuario = new Usuario();
	unidadeSaude = new UnidadeSaude();
}

export class PerfilHasTipoAtendimento {
	id;
	tipoAtendimento = new TipoAtendimento;
	perfil = new Perfil;
	medico;
}