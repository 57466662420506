import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TrumbowygModule } from "ng2-lazy-trumbowyg";
import { NgxMaskModule } from "ngx-mask";
import { PaginationModule } from '../core/pagination/pagination.module';
import { PerfilService } from "../core/services/perfil-permissao/perfil.service";
import { GetAdress } from "../core/services/utils/get-address.service";
import { SharedsModule } from "../shareds/shareds.module";
import { PerfilHasPerfilService } from './../core/services/perfil-permissao/perfil-has-perfil.service';
import { ConviteComponent } from "./convite/convite.component";
import { UsuarioCadastroComponent } from "./usuario-cadastro/usuario-cadastro.component";
import { UsuarioGerenciarComponent } from "./usuario-gerenciar/usuario-gerenciar.component";
import { UsuarioListarComponent } from "./usuario-listar/usuario-listar.component";
import { UsuarioViewComponent } from "../home/info/usuario-view/usuario-view.component";
import { UsuarioRoutingModule } from "./usuario.routing.module";
import { UsuarioService } from "./usuario.service";
import { ImageCropperModule } from "ngx-image-cropper";
import { UtilService } from "../core/services/utils/util.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    UsuarioRoutingModule,
    PaginationModule,
    SharedsModule,

    NgxMaskModule,
    ImageCropperModule,
  

    TrumbowygModule.forRoot({
      plugins: ["colors", "noembed", "preformatted", "pasteimage", "upload"],
      version: "2.8.0"
    })
  ],
  declarations: [
    ConviteComponent,
    UsuarioCadastroComponent,
    UsuarioGerenciarComponent,
    UsuarioListarComponent,
    UsuarioViewComponent
  ],
  providers: [
    PerfilService,
    GetAdress,
    UsuarioService,
    PerfilHasPerfilService,
    UtilService
  ]
})
export class UsuarioModule { }
